/* eslint-disable */

<template>
  <div class="main-border rounded-lg h-full">
    <div>
      <h4 class="p-4">{{ activeCourseInfo.name }} Progress</h4>

      <div
        class="flex justify-between items-center main-top-border p-4"
        v-if="!courseInfoFetched || (courseInfoFetched && progressData.simulations)"
      >
        <div>
          <h4 class="mb-1">Simulations</h4>
          <span class="flex items-center gap-x-2">
            <font-awesome-icon icon="fa-regular fa-circle-check" class="h-5 w-5 text-success" />
            Completed Sims:
            <p v-if="courseInfoFetched">{{ progressData?.simulations?.completedCount }}</p>
            <p v-else>0</p>
          </span>

          <span class="flex items-center gap-x-2">
            <font-awesome-icon icon="fa-regular fa-circle-x" class="h-5 w-5 text-danger" />
            Incomplete Sims:
            <p v-if="courseInfoFetched">{{ progressData?.simulations?.incompleteCount }}</p>
            <p v-else>0</p>
          </span>

          <span class="flex items-center gap-x-2">
            <font-awesome-icon icon="fa-regular fa-circle-exclamation" class="h-5 w-5 text-warning" />
            Current Grade:
            <p v-if="courseInfoFetched">{{ progressData?.simulations?.totalGrade }}</p>
            <p v-else>0</p>
          </span>
        </div>
        <div>
          <radial-progress-bar
            :diameter="95"
            :completed-steps="progressData?.simulations?.completedCount"
            :total-steps="progressData?.simulations?.total"
            :animateSpeed="1000"
            timingFunc="ease"
            innerStrokeColor="#181d2a"
            startColor="#31B952"
            stopColor="#0cdc62"
            :strokeWidth="6"
            :innerStrokeWidth="6"
          >
            <h2 v-if="courseInfoFetched" class="font-light">{{ Math.round(progressData?.simulations?.totalGradePercent) }}%</h2>
            <h2 v-else class="font-light">0%</h2>
          </radial-progress-bar>
        </div>
      </div>

      <div
        class="flex justify-between items-center main-top-border p-4"
        v-if="!courseInfoFetched || (courseInfoFetched && progressData.flashcards)"
      >
        <div>
          <h4 class="mb-1">FlashCards</h4>
          <span class="flex items-center gap-x-2">
            <font-awesome-icon icon="fa-regular fa-circle-check" class="h-5 w-5 text-success" />
            Completed Sets:
            <p v-if="courseInfoFetched">{{ progressData?.flashcards?.completedCount }}</p>
            <p v-else>0</p>
          </span>

          <span class="flex items-center gap-x-2">
            <font-awesome-icon icon="fa-regular fa-circle-x" class="h-5 w-5 text-danger" />
            Incomplete Sets:
            <p v-if="courseInfoFetched">{{ progressData?.flashcards?.incompleteCount }}</p>
            <p v-else>0</p>
          </span>

          <span class="flex items-center gap-x-2">
            <font-awesome-icon icon="fa-regular fa-circle-exclamation" class="h-5 w-5 text-warning" />
            Your Grade:
            <p v-if="courseInfoFetched">{{ progressData?.flashcards?.totalGrade }}</p>
            <p v-else>0</p>
          </span>
        </div>
        <div>
          <radial-progress-bar
            :diameter="95"
            :completed-steps="progressData?.flashcards?.completedCount"
            :total-steps="progressData?.flashcards?.total"
            :animateSpeed="1000"
            timingFunc="ease"
            innerStrokeColor="#181d2a"
            startColor="#31B952"
            stopColor="#0cdc62"
            :strokeWidth="6"
            :innerStrokeWidth="6"
          >
            <h2 v-if="courseInfoFetched" class="font-light">{{ Math.round(progressData?.flashcards?.totalGradePercent) }}%</h2>
            <h2 v-else class="font-light">0%</h2>
          </radial-progress-bar>
        </div>
      </div>

      <div
        class="flex justify-between items-center main-top-border p-4"
        v-if="!courseInfoFetched || (courseInfoFetched && progressData.examedge)"
      >
        <div>
          <h4 class="mb-1">ExamEdge</h4>
          <span class="flex items-center gap-x-2">
            <font-awesome-icon icon="fa-regular fa-circle-check" class="h-5 w-5 text-success" />
            Passed Asgmts:
            <p v-if="courseInfoFetched">{{ progressData?.examedge?.passedCount }}</p>
            <p v-else>0</p>
          </span>

          <span class="flex items-center gap-x-2">
            <font-awesome-icon icon="fa-regular fa-circle-x" class="h-5 w-5 text-danger" />
            Incomplete Asgmts:
            <p v-if="courseInfoFetched">{{ progressData?.examedge?.incompleteCount - progressData?.examedge?.incompleteCount }}</p>
            <p v-else>0</p>
          </span>

          <span class="flex items-center gap-x-2">
            <font-awesome-icon icon="fa-regular fa-circle-exclamation" class="h-5 w-5 text-warning" />
            Your Grade:
            <p v-if="courseInfoFetched">{{ progressData?.examedge?.totalGrade }}</p>
            <p v-else>0</p>
          </span>
        </div>
        <div>
          <radial-progress-bar
            :diameter="95"
            :completed-steps="progressData?.examedge?.passedCount"
            :total-steps="progressData?.examedge?.total"
            :animateSpeed="1000"
            timingFunc="ease"
            innerStrokeColor="#181d2a"
            startColor="#31B952"
            stopColor="#0cdc62"
            :strokeWidth="6"
            :innerStrokeWidth="6"
          >
            <h2 v-if="courseInfoFetched" class="font-light">{{ Math.round(progressData?.examedge?.totalGradePercent) }}%</h2>
            <h2 v-else class="font-light">0%</h2>
          </radial-progress-bar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';
import RadialProgressBar from 'vue-radial-progress';

export default {
  data() {
    return {
      courseInfoFetched: false,
      examEdgePercentFormatted: 0,
      progressData: {},
    };
  },
  methods: {
    fetchCourseProgressData() {
      if (this.activeCourseInfo.id !== '') {
        this.$http
          .post('user/@me/compliance', { courseId: this.activeCourseInfo.id })
          .then((response) => {
            this.progressData = response.data.insights;
            this.courseInfoFetched = true;
          })
          .catch((error) => {
            console.error('Error fetching progress data:', error);
          });
      }
    },
  },
  components: {
    Prism,
    RadialProgressBar,
  },
  computed: {
    activeCourseInfo() {
      return this.$store.state.AppActiveCourse;
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  mounted() {
    this.fetchCourseProgressData();
  },
};
</script>
